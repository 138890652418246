import React from 'react'
import fnFormatTime from '@functions/fnFormatTime'
import fnGetScheduleModeName from '@functions/fnGetScheduleModeName'

export default function fnGetNotificationData(error, values) {
    let mode = 'error'
    let title
    let message

    switch (error) {
        case 'AUTHENTICATION_FAILED':
            title = 'Error al iniciar sesión'
            message = 'Verifica que tus credenciales sean correctas.'
            break
        case 'PLAN_LIMIT_REACHED':
            title = 'Necesitas un nuevo tu plan'
            message = (
                <>
                    Tu estudio está creciendo, <span className='lmp-regular'>haz llegado al límite de tu plan</span>,
                    ponte en contacto para cambiarte a un nuevo plan.
                </>
            )
            break
        case 'UNIQUE_DOMAIN_ERROR':
            title = 'Subdominio no disponible'
            message = 'El subdominio que escogiste para tu estudio no está disponible, elige otro subdominio.'
            break
        case 'STUDENT_LINKED_TO_PACKAGE':
            title = 'Paquete duplicado'
            message = 'Este paquete ya ha sido asignado al estudiante.'
            break
        case 'INSTRUCTOR_OR_STUDENT_EXISTS':
            title = 'Correo electrónico duplicado'
            message = 'Un instructor o estudiante con este correo electrónico ya ha sido registrado.'
            break
        case 'BOUNCE':
            title = 'Correo electrónico inválido'
            message = (
                <>
                    No es posible usar el correo electrónico <span className='color-mandy'>{values.email}</span>.
                    Verifica que la dirección sea correcta o utiliza otra dirección.
                </>
            )
            break
        case 'COMPLAINT':
            title = 'Correo electrónico inválido'
            message = (
                <>
                    <p>
                        El correo electrónico <span className='color-mandy'>{values.email}</span> ha solicitado no
                        recibir notificaciones de <span className='color-mandy'>Tribu</span>, por lo tanto{' '}
                        <span className='color-mandy lmp-medium'>no es posible</span> crear este usuario.
                    </p>
                    <p>Utiliza otra dirección de correo electrónico.</p>
                </>
            )
            break
        case 'PASSWORD_NOT_UPDATED':
            title = 'Contraseña incorrecta'
            message = 'Verifica que tu contraseña actual sea correcta e inténtalo nuevamente.'
            break
        case 'RECORDING_DUPLICATED':
            title = 'Grabación duplicada'
            message = 'Ya existe una grabación para la fecha y horario seleccionado.'
            break
        case 'RECORDING_NOT_ALLOWED':
            title = 'Fecha incorrecta'
            message = 'Únicamente clases pasadas y no canceladas pueden contener grabaciones'
            break
        case 'SCHEDULE_DUPLICATED':
            title = 'Horario duplicado'
            message = (
                <>
                    Ya existe un horario para los días seleccionados a las{' '}
                    <span className='color-mandy'>{fnFormatTime(values.time)}</span> en modalidad{' '}
                    <span className='color-mandy'>{fnGetScheduleModeName(values.mode)}</span>. Selecciona otra hora u
                    otro día.
                </>
            )
            break
        case 'SCHEDULES_LINKED':
            title = 'Horarios asociados'
            message = 'Existe horarios asociados, no es posible eliminar los datos.'
            break
        case 'MISSING_ZOOM_CONFIG':
        case 'ZOOM_CANNOT_GET_ACCESS_TOKEN':
        case 'INVALID_ZOOM_ACTION':
            title = 'Error: configuración de Zoom'
            message = 'Tu credenciales de Zoom son invalidas, verifica los pasos de configuración nuevamente.'
            break
        case 'INVALID_INSTRUCTOR_CONFIG':
            title = 'Error: cambios no guardados'
            message = 'El instructor de este horario no está activo o aún no ha sido verificado.'
            break
        case 'PENDING_REVIEW_PAYMENTS_LINKED':
            title = 'No se puede eliminar'
            message =
                'Existen comprobantes de pago sin verificar, no es posible eliminar los datos hasta verificar cada comprobante.'
            break
        case 'PACKAGE_PRICE_MISSING':
        case 'SERVICE_PRICE_MISSING':
            title = 'Define el precio'
            message = 'Es necesario definir al menos un precio mayor a cero si el producto no es gratuito.'
            break
        case 'INVALID_DATA':
            title = 'Datos incorrectos'
            message = 'Algo salió mal; verifica que todos los campos sean correctos.'
            break
        case 'NOT_FOUND':
        case 'UNAUTHORIZED':
            title = 'Datos no encontrados'
            message = 'La información que buscas ha sido eliminada'
            break
        case 'API_ERROR':
            title = `Error: ${values.error}`
            message = values.message
            break
        case 'TAGS_LIMITS_REACHED':
            title = 'Límite de etiquetas alcanzado!'
            message = 'Has alcanzado el límite de etiquetas que puedes crear para tu estudio.'
            break
        case 'TESTIMONIALS_LIMITS_REACHED':
            title = 'Límite de testimonios alcanzado!'
            message = 'Has alcanzado el límite de testimonios que puedes crear para tu estudio.'
            break
        case 'PUSH_SUBSCRIPTIONS_LIMITS_REACHED':
            title = 'Límite de dispositivos alcanzado!'
            message = 'Has alcanzado el límite de dispositivos en los que puedes recibir notificaciones.'
            break
        case 'PAYMENT_NOT_DELETABLE':
            title = '¡Error!'
            message = 'Únicamente pagos denegados pueden ser eliminados.'
            break
        case 'SERVER_ERROR':
            title = '¡Error!'
            message = 'Ocurrió un error en nuestros servidores; inténtalo más tarde nuevamente.'
            break
        default:
            break
    }

    //
    // returning null avoids displaying the notification
    if (!title && !message) {
        return null
    }

    return { mode, title, message }
}
