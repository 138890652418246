module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"id":"/","name":"Tribu Yoga","short_name":"Tribu Yoga","start_url":"/","background_color":"#FFFFFF","theme_color":"#242628","display":"standalone","include_favicon":false,"orientation":"portrait","icons":[{"src":"/icon_48x48.png","type":"image/png","sizes":"48x48","purpose":"any"},{"src":"/icon_72x72.png","type":"image/png","sizes":"72x72","purpose":"any"},{"src":"/icon_96x96.png","type":"image/png","sizes":"96x96","purpose":"any"},{"src":"/icon_144x144.png","type":"image/png","sizes":"144x144","purpose":"any"},{"src":"/icon_192x192.png","type":"image/png","sizes":"192x192","purpose":"any"},{"src":"/icon_256x256.png","type":"image/png","sizes":"256x256","purpose":"any"},{"src":"/icon_384x384.png","type":"image/png","sizes":"384x384","purpose":"any"},{"src":"/icon_512x512.png","type":"image/png","sizes":"512x512","purpose":"any"},{"src":"/icon_maskable_48x48.png","type":"image/png","sizes":"48x48","purpose":"maskable"},{"src":"/icon_maskable_72x72.png","type":"image/png","sizes":"72x72","purpose":"maskable"},{"src":"/icon_maskable_96x96.png","type":"image/png","sizes":"96x96","purpose":"maskable"},{"src":"/icon_maskable_144x144.png","type":"image/png","sizes":"144x144","purpose":"maskable"},{"src":"/icon_maskable_192x192.png","type":"image/png","sizes":"192x192","purpose":"maskable"},{"src":"/icon_maskable_256x256.png","type":"image/png","sizes":"256x256","purpose":"maskable"},{"src":"/icon_maskable_384x384.png","type":"image/png","sizes":"384x384","purpose":"maskable"},{"src":"/icon_maskable_512x512.png","type":"image/png","sizes":"512x512","purpose":"maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":null},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
