import { useState } from 'react'
import { QueryClient, QueryCache, MutationCache, matchQuery } from '@tanstack/react-query'
import fnGetNotificationData from '@functions/fnGetNotificationData'

export default function useReactQueryClient(setNotification) {
    const [queryClient] = useState(
        () =>
            new QueryClient({
                queryCache: new QueryCache({
                    onSuccess: async (response) => {
                        if (response === 'NO_SESSION') {
                            return queryClient.setQueryData(['studio'], response)
                        }
                    },
                    onError: (error, variables) => {
                        if (error?.error) {
                            setNotification(fnGetNotificationData('API_ERROR', error))
                        } else {
                            setNotification(fnGetNotificationData(error, variables))
                        }
                    },
                }),
                mutationCache: new MutationCache({
                    onSuccess: async (response, variables, context, mutation) => {
                        if (response === 'NO_SESSION') {
                            return queryClient.setQueryData(['studio'], response)
                        }

                        const invalidateQueries = mutation.meta?.invalidateQueries ?? []
                        const invalidateExact = mutation.meta?.invalidateExact ?? false
                        const skipSuccessMessage = mutation.meta?.skipSuccessMessage ?? false

                        await queryClient.invalidateQueries({
                            predicate: (query) =>
                                invalidateQueries.some((queryKey) =>
                                    matchQuery({ queryKey: queryKey, exact: invalidateExact }, query),
                                ),
                        })

                        if (!skipSuccessMessage) {
                            setNotification({
                                mode: 'success',
                                title: '¡Perfecto!',
                                message: 'Los cambios se han guardado.',
                            })
                        }
                    },
                    onError: (error, variables) => {
                        if (error?.error) {
                            setNotification(fnGetNotificationData('API_ERROR', error))
                        } else {
                            setNotification(fnGetNotificationData(error, variables))
                        }
                    },
                }),
            }),
    )

    return queryClient
}
